import React from 'react';
import { shape, string } from 'prop-types';

import VideoEmbed from './VideoEmbed';
import IframeEmbed from './IframeEmbed';
import ImageEmbed from './ImageEmbed';

const IMAGE_REGEX = /http[^?]*.(jpg|jpeg|gif|png|tiff|bmp)(\?(.*))?$/gim;

const isImage = (url) => {
  const match = url.match(IMAGE_REGEX);

  return match !== null;
};

function EmbedfromSource({ embedMetadata }) {
  if (!embedMetadata) {
    return null;
  }

  switch (embedMetadata.source) {
    case 'VIDEO':
      return <VideoEmbed embedMetadata={embedMetadata} />;
    case 'EMBED': {
      if (isImage(embedMetadata.url)) {
        return <ImageEmbed embedMetadata={embedMetadata} />;
      }

      return <IframeEmbed embedMetadata={embedMetadata} />;
    }
    default:
      return null;
  }
}

EmbedfromSource.propTypes = {
  embedMetadata: shape({
    source: string.isRequired,
    url: string.isRequired,
    props: shape({}),
  }),
};

EmbedfromSource.defaultProps = {
  embedMetadata: {},
};

export default EmbedfromSource;
