import React from 'react';
import ReactPlayer from 'react-player';
import { bool, shape, string } from 'prop-types';

import './styles.scss';

function VideoEmbed({ embedMetadata }) {
  const { autoplay, loop, showControls, muted } = embedMetadata.props;

  return (
    <ReactPlayer
      className="embed-player"
      width="100%"
      height="100%"
      loop={loop}
      playing={autoplay}
      controls={showControls}
      muted={muted}
      url={embedMetadata.url}
    />
  );
}

VideoEmbed.propTypes = {
  embedMetadata: shape({
    url: string.isRequired,
    props: shape({
      autoplay: bool,
      loop: bool,
      showControls: bool,
      muted: bool,
    }),
  }),
};

VideoEmbed.defaultProps = {
  embedMetadata: {
    props: {
      autoplay: false,
      loop: false,
      showControls: false,
      muted: false,
    },
  },
};

export default VideoEmbed;
