import React from 'react';
import { shape, string } from 'prop-types';

function ImageEmbed({ embedMetadata }) {
  return <img alt="" className="image-embed" src={embedMetadata.url} />;
}

ImageEmbed.propTypes = {
  embedMetadata: shape({
    url: string.isRequired,
  }).isRequired,
};

ImageEmbed.defaultProps = {};

export default ImageEmbed;
