import React from 'react';
import { shape, string } from 'prop-types';
import { ArrowUpRightSquare } from 'react-bootstrap-icons';

import PrimitiveButton from 'common/Buttons/Primitive';

function IframeEmbed({ embedMetadata }) {
  const config = 'toolbar=0,location=0,menubar=0,width=800,height=600';
  const handlePopOutClick = () => {
    window.open(embedMetadata.url, '_blank', config);
  };

  return (
    <div className="full-size-embed">
      <PrimitiveButton
        onClick={handlePopOutClick}
        className="pop-out-button p-1 d-flex"
      >
        <ArrowUpRightSquare />
      </PrimitiveButton>
      <iframe
        title="iframe-embed"
        width="100%"
        height="100%"
        src={embedMetadata.url}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      />
    </div>
  );
}

IframeEmbed.propTypes = {
  embedMetadata: shape({
    url: string.isRequired,
  }).isRequired,
};

IframeEmbed.defaultProps = {};

export default IframeEmbed;
