/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { bool, shape } from 'prop-types';
import Image from 'react-bootstrap/Image';
import { isEqual, get } from 'lodash';

import useWindowResize from 'utils/useWindowResize';

import Hotspot from './components/Hotspot';
import MobileDisplay from './components/MobileDisplay';
import WebDisplay from './components/WebDisplay';
import FlowSelection from './components/FlowSelection';
import useTour from './useTour';
import useTourArrowNavigation from './useTourArrowNavigation';
import useTrackActivity from './useTrackActivity';
import useCachePreloadSteps from './useCachePreloadSteps';
import './styles.scss';

import EmbedfromSource from './components/Embeds';

const isUXMessage = (type) => type === 'introduction' || type === 'conclusion';

function GuidedTour({ tourData, trackActivity, forceMobile }) {
  const [dimensionLoaded, setDimensionLoaded] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const imageRef = useRef();
  const [parentDimension, setParent] = useState(null);
  const tour = useTour(tourData);
  const [displayType] = useState(get(tourData, 'demo.product_type'));
  useCachePreloadSteps(
    get(tour, 'stepIndex'),
    get(tour, 'activeFlow.steps', []),
  );
  useTrackActivity(trackActivity, tour, tourData);
  const currentStep = useMemo(() => {
    const step =
      tour.stepIndex > -1 ? tour.activeFlow.steps[tour.stepIndex] : null;
    return step;
  }, [tour.activeFlow.steps, tour.stepIndex]);

  useWindowResize(() => {
    const newParentDimensions = {
      width: get(imageRef, 'current.offsetWidth', 0),
      height: get(imageRef, 'current.offsetHeight', 0),
    };
    setParent(newParentDimensions);
  }, []);

  const currentImage = get(currentStep, 'imageUrl');
  useEffect(() => {
    if (!imageLoaded) return;
    const refHeight = get(imageRef, 'current.offsetHeight', 0);
    const refWidth = get(imageRef, 'current.offsetWidth', 0);
    const newParentDimensions = {
      width: refWidth,
      height: refHeight,
    };
    if (!isEqual(newParentDimensions, parentDimension)) {
      setParent(newParentDimensions);
    }
    setDimensionLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, imageLoaded]);

  useEffect(() => {
    const isCached = get(imageRef, 'current.complete', false);
    setImageLoaded(isCached);
  }, [currentImage]);

  const onNext = () => {
    setDimensionLoaded(false);
    tour.goToNext();
  };

  const onBack = () => {
    setDimensionLoaded(false);
    tour.goBack();
  };

  const onLoad = () => {
    setImageLoaded(true);
  };

  const handleTourAction = () => {
    if (currentStep.type === 'conclusion') {
      tour.reset();
    } else {
      onNext();
    }
  };

  const selectFlow = (flowId) => {
    tour.changeFlow(flowId);
  };

  const isMobile = forceMobile || displayType === 'MOBILE';
  const Display = isMobile ? MobileDisplay : WebDisplay;

  useTourArrowNavigation(tour.activeFlow, currentStep, {
    right: onNext,
    left: onBack,
  });

  const isReady = dimensionLoaded && imageLoaded;

  const hotspots = get(currentStep, 'hotspots', []);

  return (
    <div>
      <div className="d-flex align-items-center justify-content-center">
        {currentStep && (
          <Display>
            {isUXMessage(currentStep.type) && (
              <FlowSelection
                activeFlowId={tour.activeFlow.id}
                flows={tour.flowOptions}
                selectFlow={selectFlow}
                step={currentStep}
                tourAction={handleTourAction}
                tourIsReady={isReady}
              />
            )}
            <Image
              onLoad={onLoad}
              fluid
              src={currentStep.imageUrl}
              ref={imageRef}
            />
            {!isUXMessage(currentStep.type) && isReady && (
              <div className="hotspot-layer-wrapper">
                {hotspots.map((hotspot) => (
                  <Hotspot
                    key={hotspot.id}
                    isMobile={isMobile}
                    parentDimension={parentDimension}
                    active
                    onClick={onNext}
                    format={currentStep.format}
                    embed={
                      hotspot.event === 'embed' && (
                        <EmbedfromSource
                          embedMetadata={hotspot.embedMetadata}
                        />
                      )
                    }
                    {...hotspot}
                  />
                ))}
              </div>
            )}
          </Display>
        )}
      </div>
    </div>
  );
}

GuidedTour.propTypes = {
  forceMobile: bool,
  tourData: shape({}).isRequired,
  trackActivity: bool,
};

GuidedTour.defaultProps = {
  forceMobile: false,
  trackActivity: true,
};

export default GuidedTour;
